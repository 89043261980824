<template>
  <div>
    <default-count-card 
      :flag='flag' 
      :title_with_icon="false" 
      :value="data" 
      :title="title" 
      :title_class="title_class" 
      style="height: 100%"
      not_rounded
    >
      <template v-slot:extra>

      </template>
    </default-count-card>
  </div>
</template>

<script>
  export default {
    name: 'CountCountryesSettings',
    components: {
      DefaultCountCard: () => import ('../default_counter_card/DefaultCountCard.vue'),
    },
    props: {
      data: {
        type: Number
      },
      title: {
        type: String
      },
      flag: {
        type: String
      }
    },
    data() {
      return {
        title_class: 'primary--text overline text-center',
      }
    },
  }
</script>

<style lang="scss" scoped>

</style>